<template>
  <RsModal
    class="modal-check-deposit__wrapper"
    :title="$t('certificatePage.depositCheckModal.title')"
    @dismiss="closeModal"
  >
    <template #header>
      <div class="flex justify-between items-end w-full md:px-4">
        <RsHeadline variant="h2">
          {{ $t('certificatePage.depositCheckModal.title') }}
        </RsHeadline>
        <IcCloseBlack class="cursor-pointer" width="24" @click="closeModal"/>
      </div>
    </template>

    <div class="max-h-[640px] flex flex-col space-y-4 py-4">
      <div class="relative flex justify-center items-center">
        <img src="/img/deposit-missing-qrcode.svg">

        <div class="absolute w-28 xs:w-32 mt-8">
          <RsQrCode v-if="pickupLink" :qr-value="pickupLink" />
        </div>
      </div>
      <p class="font-semibold text-xl">{{ $t('certificatePage.depositCheckModal.depositMissingTitle') }}</p>
      <p>{{ $t('certificatePage.depositCheckModal.depositMissingMessage') }}</p>
    </div>
  </RsModal>
</template>

<script lang="ts" setup>
import { IcCloseBlack } from '~/components/common/icons'
import RsQrCode from '~/components/core/RsQrCode.vue'

const props = defineProps<{
  pickupLink: {
    type: String,
    required: true
  }
}>()
const { pickupLink } = toRefs(props)

const emit = defineEmits(['commit'])
const closeModal = () => emit('commit')



</script>

<style lang="postcss" scoped>
.modal-check-deposit__wrapper :deep(.modal__window) {
  @apply max-h-[640px] rounded-lg h-auto;
}
.modal-check-deposit__wrapper :deep(.modal__body) {
  @apply max-h-full;
}
.modal-check-deposit__wrapper :deep(.modal__header) {
  @apply border-b-0;
}
.modal-check-deposit__wrapper :deep(.modal__footer) {
  @apply border-t-0;
}
.modal-check-deposit__wrapper {
  @apply !p-4 items-center;
}
</style>